import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterComponent } from '../common/footer/footer.component';
import { HeaderComponent } from '../common/header/header.component';
import { LoaderComponent } from '../common/loader/loader.component';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-enterprise',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule, HeaderComponent, FooterComponent, LoaderComponent],
  templateUrl: './enterprise.component.html',
  styleUrl: './enterprise.component.css'
})
export class EnterpriseComponent {
  @ViewChild('myModal') myModal: ElementRef | undefined;

  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
  cpassword: string | undefined;
  userForm: any;
  acceptTerms: any
  isSubmitted = false;
  loading: boolean = false

  constructor(
      private userService: UserService, 
      private fb: FormBuilder, 
      private route: Router,
      private toastr: ToastrService
    ) {

    this.createForm();

  }

  createForm() {
    this.userForm = this.fb.group({
      name: ['', Validators.required], // Set up validation rules
      phone: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]], // Set up validation rules
      email: ['', [Validators.required, Validators.email]],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern("^[0-9]{6}$")]],
      company_name: ['', Validators.required],
      team_size: ['', Validators.required],
    }

    );
  }


  onSubmit() {

    let obj = {
      name: this.userForm.controls['name'].value,
      phone: this.userForm.controls['phone'].value,
      email: this.userForm.controls['email'].value,
      address: this.userForm.controls['address'].value,
      city: this.userForm.controls['city'].value,
      state: this.userForm.controls['state'].value,
      zip: this.userForm.controls['zip'].value,
      company_name: this.userForm.controls['company_name'].value,
      team_size: this.userForm.controls['team_size'].value
    }

    this.userService.saveEnterpriseEnquiry(obj)
    .subscribe((response :any) => {
      console.log("--- ::: ", response)
      if(response && response.status == "success"){
        this.toastr.success(response.message)
        this.userForm.reset()
      }else{
        this.toastr.error("Oops something went wrong")
      }
    })
  }


  openModal() {
    this.myModal!.nativeElement.style.display = "block";

  }
  closeModal() {
    this.myModal!.nativeElement.style.display = 'none';
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}
