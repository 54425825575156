import { Inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const authGuard: CanActivateFn = (route, state) => {
   const router = Inject(Router);
  const userId = localStorage.getItem("id");
  if (userId != null) {

    return true;
  } else {
    //window.location.href = 'sign-in'
    router.navigateByUrl('/sign-in');
    return false;
  }


};
