import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from "../common/header/header.component";
import { FooterComponent } from "../common/footer/footer.component";
import { UserService } from '../services/user.service';
import { FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from "../common/loader/loader.component";
import { ToastrService } from 'ngx-toastr';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SafePipe } from '../pipe/safe-url';

declare var Razorpay: any;

@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: './home.component.html',
    styleUrl: './home.component.css',
    imports: [CommonModule, RouterLink, NgbAccordionModule, HeaderComponent, 
      FooterComponent, LoaderComponent, CarouselModule, SafePipe]
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('myModal') myModal: ElementRef | undefined;
  @ViewChild('youtube') youtube!: ElementRef;

  items = ['First', 'Second', 'Third'];
  planResponse: any;
  loading: boolean = true

  username = localStorage.getItem('name');
  useremail = localStorage.getItem('email');
  user_subscription_plan_id: any;
  selectedPlan: any
  youtubeVideo: any 

  youtubeVideos = [
    "https://www.youtube.com/embed/tS1maA1Gv1Y",
    "https://www.youtube.com/embed/2hJFqsp2spw",
    "https://www.youtube.com/embed/QmIOgPYsc1M",
    "https://www.youtube.com/embed/LO3425-gEuE"
  ]

  bannerOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    autoplaySpeed: 900,
    dotsSpeed: 500,
    dotsContainer: false,
    navText: ['<i class="fa fa-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-arrow-right" aria-hidden="true"></i>'],
    responsive: {
      0: {
        items: 1
      }
    },
    nav: true
  }

  youtubeOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    // autoplay: true,
    // autoplayTimeout: 3000,
    // autoplayHoverPause: true,
    // autoplaySpeed: 900,
    dotsSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      }
    },
    nav: true
  }

  customOptions: any = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    autoplaySpeed: 900,
    dotsSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  ngAfterViewInit(): void {
    //this.openModal();
  }

  constructor(
    private router: Router,
    private dataService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ){}

  doLogin(){
    this.toastr.warning('Please login first')
    this.router.navigateByUrl('sign-in')
  }


  openModal() {
    this.myModal!.nativeElement.style.display = "block";

  }
  closeModal() {
    this.myModal!.nativeElement.style.display = 'none';
  }

  ngOnInit(): void {

    this.getplan()
    
  }

  checkUserSubscription(){
    this.dataService.userSubscriptionPlan({
      user_id: localStorage.getItem("id"),
      version: localStorage.getItem("selectedVersion") ?? '1.0'
    }).subscribe(res => {
      //console.log(res);
        console.log('checkUserSubscription',res)
        this.user_subscription_plan_id = res.user_subscription_plan_id
    });
  }

  goToPage(url: string) {
    // navigation
  }

  getplan(plan_cycle:any='monthly'){
    this.loading = true
    this.dataService.getSubscriptions(plan_cycle)
    .subscribe((planResponse: any) => {

    this.planResponse = planResponse.results
    this.loading = false
    console.log('plans',planResponse)
      if(localStorage.getItem("id")){
        this.checkUserSubscription()
      }
    }, (error) => {
      this.loading = false
    })
  }

  subscribPlan(planData: any){
    this.loading = true
    this.selectedPlan = planData.id 

    let subscriptionData = {
      //plan_id: planData.id, // razorpay plan id
      plan_id: planData.razorpay_subscription_plan_id,
      total_count: 12
    }

    let plan_amount = planData.amount * 100 ;
    this.dataService.createSubscription(subscriptionData)
    .subscribe((subscribResponse: any) => {

      console.log('subscription response',subscribResponse);
      return new Promise((resolve, reject) => {
        const options = {
          key: 'rzp_live_DdAoY38gqlA4fo',
          amount: plan_amount,
          currency: 'INR', // Change to your currency
          name: 'TaxBotGPT.ai',
          description: 'TaxBotGPT Subscription',
          image: 'assets/images/taxbotgpt-60.png', // URL of your company logo
          subscription_id: planData.plan_OAeSEjuiEi5J1U, // Order ID generated from server
          handler: (response: any) => {
            this.savePaymentDetail(response, subscribResponse.id)
            resolve(response);
          },
          prefill: {
            email: this.useremail
          },
          theme: {
            color: '#F37254'
          }
        };
        const razorpay = new Razorpay(options);
        razorpay.open();
        this.loading = false
      });
    })
  }

  savePaymentDetail(paymentResponse: any, subscription_id: any){
    if(paymentResponse && paymentResponse.razorpay_payment_id){
      this.dataService.getPaymentDetail(paymentResponse.razorpay_payment_id)
      .subscribe((paymentDetail: any) => {
        if(paymentDetail && (paymentDetail.status == "authorized" || paymentDetail.status == "captured")){
          let subscriptionObj = {
            user_id: localStorage.getItem("id"),
            plan_id: this.selectedPlan,
            payment_id: paymentResponse.razorpay_payment_id
          }
          this.dataService.saveSubscriptionDetail(subscriptionObj)
          .subscribe((response: any) => {
            if(response && response.status == "success"){
              this.toastr.success("Subscribed Successfully")
              // this.router.navigateByUrl('dashboard')
              window.location.href = '/dashboard'
            }
          })
        }
      })
    }
  }

  showVideo(video: any){
    this.youtubeVideo = video
    this.youtube.nativeElement.click()
  }

}
