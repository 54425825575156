<app-header></app-header>
<section class="main-banner p-0">
    <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide position-relative">
          <img src="assets/images/internal-banner.jpg" alt="" class="img-fluid">
          <div class="internal-headercontent">
            <h1> About <span> Us </span> </h1>
            <p> TaxBOTGPT:Your Personalised AI Tax Assistant </p>
          </div>
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </section>

  <div class="main">
    <section class="internal-content graybg">
        <div class="container">
            <div class="col-lg-10 m-auto text-center">
           <h2> TaxBotGPT Simplifies <br> Your Complex Tax Queries </h2>
           <p> Welcome to TaxBotGPT, your trusted companion in navigating the complexities of tax regulations. </p>
            <a href="sign-in" class="common-btn mt-5 d-inline-block"> Get Started </a>
        </div>
        </div>
    </section>


    <section class="aboutus-section">
      <div class="container">

        <div class="section-title text-center col-lg-10 m-auto">
          <h2 class="tax-heading"> TaxBOTGPT: Revolutionizing Tax Queries <br> With Natural Language Processing </h2>
          <p class="mt-3"> Navigating the complexities of tax compliance and regulation, particularly regarding income tax and Goods and Services Tax (GST), is a challenging task for both individuals and businesses. Understanding the intricacies of tax obligations, exemptions, and procedures can be overwhelming. TaxBOTGPT emerges as a groundbreaking solution aimed at simplifying tax-related inquiries through the utilization of Natural Language Processing (NLP). </p>
        </div>



<div class="about-para col-lg-10 m-auto mt-5">
<div class="row whitebg shadow rounded">
<div class="col-lg-6">
<div class="about-content p-4">
<h2> Natural Language Understanding (NLU) </h2>
<p class="mt-3"> TaxBOTGPT can grasp complex tax-related questions posed in everyday language,
  removing the need for users to navigate technical tax jargon. </p>
</div>
</div>
<div class="col-lg-6 p-0">
<img src="assets/images/nlu.jpg" class="img-fluid">
</div>
</div>
</div>


<div class="about-para col-lg-10 m-auto mt-5">
  <div class="row whitebg shadow rounded">
    <div class="col-lg-6 p-0">
      <img src="assets/images/instantreply.jpg" class="img-fluid">
      </div>
  <div class="col-lg-6">
  <div class="about-content p-4">
  <h2>  Instant Responses  </h2>
  <p class="mt-3"> It offers quick answers to a broad spectrum of tax queries, from understanding basic tax slabs to the intricate implications of GST on international transactions.
  </p>
  </div>
  </div>
  </div>
  </div>



  <div class="about-para col-lg-10 m-auto mt-5">
    <div class="row whitebg shadow rounded">
    <div class="col-lg-6">
    <div class="about-content p-4">
    <h2> Up-to-Date Information    </h2>
    <p class="mt-3"> The system is regularly updated to ensure that its knowledge base accurately reflects the most recent tax laws, amendments, and guidelines.

    </p>
    </div>
    </div>
    <div class="col-lg-6 p-0">
    <img src="assets/images/information.jpg" class="img-fluid">
    </div>
    </div>
    </div>


    <div class="about-para col-lg-10 m-auto mt-5">
      <div class="row whitebg shadow rounded">
        <div class="col-lg-6 p-0">
          <img src="assets/images/accessbility.jpg" class="img-fluid">
          </div>
      <div class="col-lg-6">
      <div class="about-content p-4">
      <h2> Accessibility:
      </h2>
      <p class="mt-3"> TaxBOTGPT is designed to be user-friendly, accessible via web interfaces, mobile applications, and even social media channels.

      </p>
      </div>
      </div>
      </div>
      </div>




      </div>
    </section>

  </div>
<app-footer></app-footer>
