<app-header></app-header>
<section class="main-banner p-0">
    <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide position-relative">
          <img src="assets/images/internal-banner.jpg" alt="" class="img-fluid">
          <div class="internal-headercontent">
            <h1> Privacy Policy <span> Us </span> </h1>
            <p> TaxBOTGPT:Your Personal Tax Assistant </p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="main">
    <section class="internal-content graybg">
        <div class="container">
            <div class="col-lg-10 m-auto text-center">
           <p style="font-size: 1.1rem; font-weight: 600; line-height: 35px;"> This Privacy Policy outlines how TaxBotGPT.ai manages personal <br> information across its website at www.TaxBotGPT.ai, its web-based services.
           </p>
        </div>
        </div>
    </section>

    <section class="privacy-content">
        <div class="container">

            <h3> Updates to this Privacy Policy </h3>
            <p class="mb-4"> TaxBotGPT.ai reserves the right to modify this Privacy Policy at any time in the future. Updates will be posted on the website accordingly. </p>


            <h3 class="mt-4"> Types of Personal Information Collected </h3>
            <p class="mt-3"> <strong>  collects personal data voluntarily provided by users, information collected
                automatically through the use of the Services, and data obtained from third-party
                sources. This includes: </strong> </p>

                <ol>
                    <li class="mt-4">
                        <strong> Directly Provided Information: </strong>
                        <p class="p-0 m-0"> Such as account details (name, email, phone number), business and financial data, purchase-related information, communications with the company, survey responses, and content from interactive features. </p>
                    </li>

                    <li>
                        <strong> Automatically Collected Information: </strong>
                        <p class="p-0 m-0"> This includes IP addresses, user settings, device and browser data, location, and usage details, collected via cookies, pixel tags, and other technologies. </p>
                    </li>

                    <li>
                        <strong> Third-Party Sourced Information: </strong>
                        <p class="p-0 m-0"> Data was obtained from services and public sources. </p>
                    </li>

                </ol>


                <h3 class="mt-5"> The Usage of the Data </h3>
                <p class="mb-4"> TaxBotGPT.ai utilizes personal data to provide and improve the Services, for administrative purposes, marketing, and as legally required or with user consent. </p>


                <h3 class="mt-5"> User’s Rights </h3>
                <p class="mb-4"> Users have choices and rights regarding their data, including opting out of email communications, and text messages, and managing cookie preferences. </p>


                <h3 class="mt-5"> Information collected from Children </h3>
                <p class="mb-4"> TaxBotGPT.ai does not intentionally collect data from children under 18. If such data is collected, it will be deleted.
                </p>

                <h3 class="mt-5"> Third-Party Websites </h3>
                <p class="mb-4"> The Services may link to third-party services, such as references to Government websites for informational purposes, which are not covered by this policy.
                </p>

                <h3 class="mt-5"> Contact Information
                </h3>
                <p class="mb-4"> For questions or concerns regarding this policy or personal data, users can contact us at contact.
                </p>

        </div>
    </section>
   </div>

<app-footer></app-footer>