<app-header></app-header>
<app-loader *ngIf="loading"></app-loader>

<div class="container register mb-4">
  <div class="row">
    <div class="col-md-5 register-left">
      <h5>Becoming a sales partner of TaxbotGPT offers numerous advantages. 
        Here are 10 key benefits:
      </h5>
      <ul style="text-align: left;">
        <li>High Demand Market</li>
        <li>Attractive Commission Structure</li>
        <li>Cutting-Edge Technology</li>
        <li>Brand Association</li>
        <li>Training and Support</li>
        <li>Growth Potential</li>
        <li>Customize Solution</li>
        <li>Flexible Work Arrangement</li>
        <li>Improved Customer Experience</li>
        <li>Wide Market Appeal</li>
        <li>Solutions for MSME and Corporates</li>
      </ul>	
    </div>
    <div class="col-md-7 register-right">
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <h3 class="register-heading">Vendor Registration</h3>
          <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
            <div class="row register-form">
              <div class="col-md-12">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Name<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    id="name" 
                    placeholder="Enter name"
                    formControlName="name">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('name')?.valid && userForm.get('name')?.touched">
                        Please enter name
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Email<span class="text-danger">*</span> </label>
                  <input 
                    type="email" 
                    class="form-control" 
                    id="exampleInputEmail1" 
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    formControlName="email">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('email')?.valid && userForm.get('email')?.touched">
                        Please enter email
                    </div>
                </div>

                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">PanCard<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="pancard" 
                    placeholder="Enter Pancard"
                    formControlName="pancard">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('pancard')?.valid && userForm.get('pancard')?.touched">
                        Please enter pancard
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Phone<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="phone" 
                    (keypress)="numberOnly($event)"
                    placeholder="Enter phone"
                    formControlName="phone">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('phone')?.valid && userForm.get('phone')?.touched">
                        Please enter phone
                    </div>
                </div>

                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">GST<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="gst" 
                    placeholder="Enter GST"
                    formControlName="gst">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('gst')?.valid && userForm.get('gst')?.touched">
                        Please enter gst
                    </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Address<span class="text-danger">*</span> </label>
                  <textarea 
                    class="form-control" 
                    name="address" 
                    placeholder="Enter Address"
                    formControlName="address"></textarea>
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('address')?.valid && userForm.get('address')?.touched">
                        Please enter address
                    </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">City<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="city" 
                    placeholder="Enter City"
                    formControlName="city">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('city')?.valid && userForm.get('city')?.touched">
                        Please enter city
                    </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">State<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="state" 
                    placeholder="Enter State"
                    formControlName="state">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('state')?.valid && userForm.get('state')?.touched">
                        Please enter state
                    </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Zipcode<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="zip" 
                    (keypress)="numberOnly($event)"
                    placeholder="Enter Zipcode"
                    formControlName="zip">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('zip')?.valid && userForm.get('zip')?.touched">
                        Please enter zip
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Company Name<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    name="company_name" 
                    placeholder="Enter Company Name"
                    formControlName="company_name">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('company_name')?.valid && userForm.get('company_name')?.touched">
                        Please enter company name
                    </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-3">
                  <label for="exampleInputEmail1">Team Size<span class="text-danger">*</span> </label>
                  <input 
                    type="text" 
                    class="form-control" 
                    (keypress)="numberOnly($event)"
                    name="teamsize" 
                    placeholder="Enter TeamSize"
                    formControlName="team_size">
                    <div class="text-danger form-error"
                        *ngIf="!userForm.get('name')?.valid && userForm.get('name')?.touched">
                        Please enter team size
                    </div>
                </div>
                <input 
                  [disabled]="!userForm.valid"
                  [class.disabled]="!userForm.valid"
                  type="submit" 
                  class="btnRegister" 
                  value="Register" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer></app-footer>