import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { LoaderComponent } from '../common/loader/loader.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.css'
})
export class ForgetPasswordComponent implements OnInit {

  forgorPasswordForm!: FormGroup
  loading: boolean = false

  constructor(
    private fb: FormBuilder,
    private dataService: UserService,
    private toastr: ToastrService,
    private router: Router
    ){}

  ngOnInit(): void {
    this.forgorPasswordForm = this.fb.group({
      email: ['', Validators.required]
    })
  }

  sendRequestToChangePassword(){
    this.loading = true
    let obj = {
      email: this.forgorPasswordForm.controls['email'].value
    }
    this.dataService.post('forgote-password', obj)
    .subscribe((response: any) => {
      this.loading = false
      console.log("response : ", response)
      if(response.status == 200){
        this.toastr.success(response.message)
        this.router.navigateByUrl('/')
      }else{
        this.toastr.error("Oops..Something went wrong!")
      }
    }, (error: any) => {
      console.log("-----: ", error)
      this.loading = false
      this.toastr.error("Oops..Something went wrong!")
    })
  }


}
