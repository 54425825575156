import { Component } from '@angular/core';
import { FooterComponent } from '../common/footer/footer.component';
import { HeaderComponent } from '../common/header/header.component';

@Component({
  selector: 'app-term-condition',
  standalone: true,
  imports: [FooterComponent, HeaderComponent],
  templateUrl: './term-condition.component.html',
  styleUrl: './term-condition.component.css'
})
export class TermConditionComponent {

}
