import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  
  baseurl         = environment.baseurl
  razorpayBaseUrl = environment.razorpayBaseUrl
  apiBaseUrl      = environment.apiBaseUrl

  constructor(private http: HttpClient) { }

 
  saveUser(name: string, email: string, password: string, referral: string, domain: string, provider: string = '') {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl +'register', { name, email, password, referral, domain, provider },{headers});
  }


  login(email: string, password: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl +'login', { email, password },{headers});
  }

  contactus(name: string, email: string, phone: string, description: string) {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl +'contactus', { name, email, phone, description },{headers});
  }

  saveVendor(obj: any){

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl +'vendors', obj,{headers});
  }


  saveEnterpriseEnquiry(obj: any){

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl +'enterprise/request', obj,{headers});
  }


  doChat(query_str: string, source: string, metadata: {}, stream: boolean) {

    let version = localStorage.getItem('selectedVersion') ?? '1.0';
    let api_version: any;
    let api_url: any; 
    let chat_obj = {}

    if(version=='1.0'){
        api_version = 'v1';
        api_url = "chat-response"
        chat_obj = {
          query_str: query_str,
          mode: "basic",
          source: source,
          metadata: metadata,
          stream: true,
        }
    }
    
    if(version=='2.0'){
      api_version = 'v2'
      api_url = "advance-chat-response"
    

      chat_obj = {
        query_str: query_str,
        mode: "advance",
        source: source,
        metadata: metadata,
        stream: true
      }

    }

    if(version=='4.0'){
      api_version = 'v2'
      api_url = "chat-response/fema"
    
      chat_obj = {
        query_str: query_str,
        mode: "basic",
        source: source,
        metadata: metadata,
        stream: true,
        chat_history: [
          {
            role: "user",
            content: ""
          }
        ]
      }

    }

    return new Observable<any>(observer => {
      fetch(`https://api.taxbotgpt.ai/${api_version}/${api_url}`, {
        method: 'POST',
        body: JSON.stringify(chat_obj),
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
          'domain': 'taxbotgpt.ai'
        }
      }).then(response => {
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();
        if (!response.ok) {
           // handle response error 
           observer.error();
        }

        function push() {
          return reader?.read().then(({ done, value }) => {
            if (done) {
              observer.complete();
              return;
            }
        
           // console.log(' before events value  ==',value);
            //parse text content from response
            const events = decoder.decode(value);

          
          //  console.log('events  = ',events)
            let content = '';
            for (let i = 0; i < events.length; i++) {
              const event = events[i];
              content += event
             // console.log(' content ==',content);
              // if (event === 'data: [DONE]') break;
              // if (event && event.slice(0, 6) === 'data: ') {
              //   const data = JSON.parse(event.slice(6));
              //   content += data.choices[0].delta?.content || '';
              // }
            }
            observer.next(content);
            push();
          });
        }
        
        push();
        
      })
    })

  }

  sessionResquest(req: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl +'chat/session/request', req, {headers});
  }

  sessionResponse(req: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl + 'session/response', req,{headers});
  }

  getsessionResponseBySessionId(sessionid: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.get<any>(this.baseurl + 'share/'+sessionid,{ headers });
  }

  sessionlist(req: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl + 'chat/session/response', req,{headers});
  }

  questionCount(req: any) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.baseurl + 'user/subscription/check', req,{headers});
  }

  getSubscriptions(cycle:any ='monthly'){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.get<any>(this.baseurl +'get/subscription/plan?billing_cycle='+cycle,{ headers });
  }

  userSubscriptionPlan(req: any) {

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl + 'user/subscription/check', req,{headers});
  }

  getPlans(){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.get<any>(this.apiBaseUrl + 'get-plan',{ headers });
  }

  createCustomer(customer_obj: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.apiBaseUrl + 'create-customer', customer_obj,{headers});
  }

  createSubscription(subscription_obj: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.apiBaseUrl + 'create-subscription', subscription_obj,{headers})
  }

  getPaymentDetail(payment_id: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.get<any>(this.apiBaseUrl + `get-paymentdetail/${payment_id}`,{ headers })
  }

  saveSubscriptionDetail(subScriptionData: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl + 'upgrade/user/subscription', subScriptionData,{headers})
  }

  saveSubscriptionRenewDetail(subScriptionData: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl + 'renew/user/subscription', subScriptionData,{headers})
  }

  updateAddress(req:any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl + 'user/update', req,{headers});
  }
  updatePassword(req:any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl + 'change/password', req,{headers});
  }

  postFeedback(feedback_obj: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.apiBaseUrl +'add-feedback', feedback_obj,{headers});
  }

  getFeedback(feedback_obj: any){

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });
    return this.http.post<any>(this.apiBaseUrl +'get-feedback', feedback_obj,{headers});
  }

  getuser(req:any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(this.baseurl + 'getuser', req,{headers});
  }

  uploadNotice(formData: any){
    let headers = new HttpHeaders({
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>('https://api.taxbotgpt.ai/v2/notice/uploads', formData,{headers});
  }

  getUploadNoticeResponse(file_id: any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.get<any>(`https://api.taxbotgpt.ai/v2/notice/${file_id}`,{headers});
  }

  post(url: any, obj: any){
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-API-KEY': '7ikW5KnL8qU7voFrmtReHtUM0sfA7x7XXSNzzmHb0VbhdVA6LP4lYPc7hs0ek9VH',
      'domain': 'taxbotgpt.ai'
    });

    return this.http.post<any>(`${this.apiBaseUrl}${url}`, obj, {headers});
  }

}
