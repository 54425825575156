import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from "../common/header/header.component";
import { FooterComponent } from "../common/footer/footer.component";

@Component({
    selector: 'app-contact-us',
    standalone: true,
    templateUrl: './contact-us.component.html',
    styleUrl: './contact-us.component.css',
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HeaderComponent, FooterComponent]
})
export class ContactUsComponent {
  contactForm: any;
  isSubmitted = false;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router) {
    this.createForm();
  }



  createForm() {
    this.contactForm = this.fb.group({

      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],

    });
  }



  doSubmit() {
    this.isSubmitted = true;
    console.log(this.contactForm)
    if (this.contactForm.valid) { // Check if the form is valid

      const formData = this.contactForm.value;
      this.userService.contactus(formData.name, formData.email, formData.phone, formData.description).subscribe(
        response => {
          console.log('Query hase been submitted successfully!', response);
          this.contactForm.reset(); // Reset the form after successful submission
          alert("Query hase been submitted successfully!")

          this.isSubmitted = false;
          //console.log(response.success.name);

        },
        error => {
          // alert("Invalid User and Password")
          console.error('Error:', error);
        }
      );
    } else {
      //alert("Please enter user and password");
    }

  }

}
