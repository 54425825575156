import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './vendor-dashboard.component.html',
  styleUrl: './vendor-dashboard.component.css'
})
export class VendorDashboardComponent {

  email: any
  @Input() referral_code: any
  @Output() sendInviteLink = new EventEmitter<any>()

  constructor(private router: Router){}
  
  sendInvite(){
    let obj = {
      email: this.email,
      vendor_id: "1"
    }
    this.sendInviteLink.emit(obj)
  }

}
