import { Component, ElementRef, HostBinding, ViewChild } from '@angular/core';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { marked } from 'marked';
import { SafeHtmlPipe } from "../pipe/safe-html.pipe";
import { ClipboardModule, Clipboard } from '@angular/cdk/clipboard';
import { CLIPBOARD_OPTIONS, ClipboardButtonComponent, MarkdownModule, provideMarkdown } from 'ngx-markdown';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shared',
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, FormsModule, ReactiveFormsModule,
    SafeHtmlPipe, ClipboardModule, MarkdownModule],
  providers:[provideMarkdown()],
  templateUrl: './shared.component.html',
  styleUrl: './shared.component.css'
})
export class SharedComponent {
  reply: any;

  @ViewChild('divscroll') divscroll: ElementRef | undefined;
  
    
  darkTheme = false;
  isChecked = false;

  

  constructor(
    private dataService: UserService,
    private _Activatedroute: ActivatedRoute,
  ) {

    this.dataService.getsessionResponseBySessionId(this._Activatedroute.snapshot.paramMap.get("sessionId")).subscribe(res => {
       console.log("------>>", res);
      this.reply = res.results

      console.log(" this.reply ",  this.reply );
      setTimeout(() => { this.divscroll!.nativeElement.scrollTop = this.divscroll!.nativeElement.scrollHeight; }, 200);

    });


  }

   



}
