<div class="login-page">
    <!-- ==== / mouse cursor drag end ==== -->
    <div id="smooth-wrapper">
        <div id="smooth-content">
            <!-- ==== main start ==== -->
            <main class="authentication">
                <section class="authentication-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="overflow-hidden radius col-md-8 col-lg-11 m-auto text-center shadow-lg"
                                style="border-radius: 5px;">
                                <div class="row">
                                    <div class="col-lg-6 p-0 loginbg">
                                        <div class="login-leftside">
                                            <h1>
                                                Your <br> Personalised <br> AI Tax <br> Assistant
                                            </h1>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 p-0">

                                        <div class="login-section authentication__content p-5">
                                            <img src="assets/images/logo.png" class="img-fluid">
                                            <div class="authentication__inner">
                                                <form [formGroup]="loginForm" (ngSubmit)="doLogin()">
                                                    <div class="input-single">
                                                        <!-- <label for="userName">Username</label> -->
                                                        <div class="ic-group">
                                                            <input type="text" formControlName="email"
                                                                placeholder="Username" required>
                                                            <span class="material-symbols-outlined">
                                                                person
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(loginForm?.get('email')?.invalid && loginForm?.get('email')?.touched ) || (loginForm?.get('email')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Please enter a valid email Id
                                                        </div>
                                                    </div>
                                                    <div class="input-single">
                                                        <!-- <label for="userPassword">Password</label> -->
                                                        <div class="ic-group pass">
                                                            <span 
                                                                (click)="showPassword()"
                                                                class="material-symbols-outlined show-pass">
                                                                {{ showPasswordLable ? 'visibility' : 'visibility_off' }}
                                                            </span>
                                                            <input type="password" formControlName="password"
                                                                placeholder="Enter Password" required>
                                                            <span class="material-symbols-outlined">
                                                                key
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(loginForm?.get('password')?.invalid && loginForm?.get('password')?.touched) ||  (loginForm?.get('password')?.invalid && isSubmitted) "
                                                            class="text-danger mt-3">
                                                            Please enter password
                                                        </div>
                                                        <a href="/forget-password">Forget Password?</a>
                                                    </div>
                                                    <div class="section__content-cta">
                                                        <button type="submit" class="btn btn--primary">Sign In</button>
                                                    </div>
                                                </form>
                                                <div class="divider">
                                                    <span></span>
                                                    <p>Or continue with</p>
                                                    <span></span>
                                                </div>
                                                <div class="auth-cta text-black">
                                                    <asl-google-signin-button type="standard" shape="rectangular"
                                                        theme="filled_black" text="signin_with" size="large"
                                                        class="google" logo_alignment="center">
                                                    </asl-google-signin-button>
                                                </div>
                                            </div>
                                            <div class="auth-footer">
                                                <p>
                                                    Don't have an account?
                                                    <a href="sign-up">Sign Up!</a>
                                                </p>
                                                <div class="section__content-cta">
                                                    <a href="/" class="btn btn--primary">Back To Home</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <!-- ==== / main end ==== -->
        </div>
    </div>
</div>