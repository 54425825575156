<app-loader *ngIf="loading"></app-loader>
<div class="login-page">
    <!-- ==== / mouse cursor drag end ==== -->
    <div id="smooth-wrapper">
        <div id="smooth-content">
            <!-- ==== main start ==== -->
            <main class="authentication">
                <section class="authentication-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="overflow-hidden radius col-md-8 col-lg-11 m-auto text-center shadow-lg"
                                style="border-radius: 5px;">
                                <div class="row">
                                    <div class="col-lg-6 p-0 loginbg">
                                        <div class="login-leftside">
                                            <h2 class="heading">
                                                Forgot your password?
                                            </h2>
                                            <p class="sub-title">
                                                Please enter the email you use to sign in to TaxBotGPT.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 p-0">

                                        <div class="login-section authentication__content p-5">
                                            <img src="assets/images/logo.png" class="img-fluid">
                                            <div class="authentication__inner">
                                                <form 
                                                    [formGroup]="forgorPasswordForm" 
                                                    (ngSubmit)="sendRequestToChangePassword()">
                                                    <div class="input-single">
                                                        <!-- <label for="userName">Username</label> -->
                                                        <div class="ic-group">
                                                            <input type="text" formControlName="email"
                                                                placeholder="Email" required>
                                                            <span class="material-symbols-outlined">
                                                                email
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(!forgorPasswordForm?.get('email')?.valid && forgorPasswordForm?.get('email')?.touched )"
                                                            class="text-danger mt-1" style="text-align: left;">
                                                            Please enter a valid email
                                                        </div>
                                                    </div>
                                                    <div class="section__content-cta">
                                                        <button 
                                                            [disabled]="!forgorPasswordForm.valid"
                                                            type="submit" 
                                                            class="btn btn--primary">
                                                            Request password reset
                                                        </button>
                                                    </div>
                                                </form>
                                                <div class="divider">
                                                    <span></span>
                                                    <p>Or continue with</p>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="auth-footer">
                                                <p>
                                                    Don't have an account?
                                                    <a href="sign-up">Sign Up!</a>
                                                </p>
                                                <div class="section__content-cta">
                                                    <a href="/" class="btn btn--primary">Back To Home</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <!-- ==== / main end ==== -->
        </div>
    </div>
</div>