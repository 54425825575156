import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent {
  username = localStorage.getItem('name');
  useremail = localStorage.getItem('email');

  constructor(private router: Router){}

  dashboard(){
    this.router.navigateByUrl('dashboard')
  }
}
