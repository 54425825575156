import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';

import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';

export const appConfig: ApplicationConfig = {
  providers: [{
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider('876371849201-amk941p2jnnpmcmlgan17vk1ln6u4b7g.apps.googleusercontent.com')
        }
      ],
      onError: (error) => {
        console.error(error);
      }
    } as SocialAuthServiceConfig,
    
  }, 
  provideRouter(routes), 
  provideHttpClient(),
  provideAnimations(),
  provideToastr()],


};
