import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, ElementRef,ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FooterComponent } from '../../common/footer/footer.component';
import { HeaderComponent } from '../../common/header/header.component';
import { LoaderComponent } from "../../common/loader/loader.component";

@Component({
  selector: 'app-vendor-register',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule, HeaderComponent, FooterComponent, LoaderComponent],
  templateUrl: './vendor-register.component.html',
  styleUrl: './vendor-register.component.css'
})
export class VendorRegisterComponent {
  @ViewChild('myModal') myModal: ElementRef | undefined;

  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
  cpassword: string | undefined;
  userForm: any;
  acceptTerms: any
  isSubmitted = false;
  loading: boolean = false

  constructor(
      private userService: UserService, 
      private fb: FormBuilder, 
      private route: Router,
      private toastr: ToastrService
    ) {

    this.createForm();

  }

  createForm() {
    this.userForm = this.fb.group({
      name: ['', Validators.required], // Set up validation rules
      phone: ['', [Validators.required, Validators.pattern("^[0-9]{10}$")]], // Set up validation rules
      email: ['', [Validators.required, Validators.email]],
      pancard: ['', Validators.required],
      gst: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.pattern("^[0-9]{6}$")]],
      company_name: ['', Validators.required],
      team_size: ['', Validators.required],
      // password: ['', Validators.required],
      // cpassword: ['', Validators.required],
      // acceptTerms: [false, Validators.requiredTrue]
    }

    );
  }


  onSubmit() {

    let vendorObj = {
      name: this.userForm.controls['name'].value,
      phone: this.userForm.controls['phone'].value,
      email: this.userForm.controls['email'].value,
      pancard: this.userForm.controls['pancard'].value,
      gst: this.userForm.controls['gst'].value,
      address: this.userForm.controls['address'].value,
      city: this.userForm.controls['city'].value,
      state: this.userForm.controls['state'].value,
      zip: this.userForm.controls['zip'].value,
      company_name: this.userForm.controls['company_name'].value,
      team_size: this.userForm.controls['team_size'].value
    }

    this.userService.saveVendor(vendorObj)
    .subscribe((response :any) => {
      this.toastr.success("Vendor registration done!")
      this.userForm.reset()
    })

    // this.isSubmitted = true;
    // if (this.userForm.valid) { // Check if the form is valid

    //   const formData = this.userForm.value;
    //   this.userService.saveUser(formData.name, formData.email, formData.password, 'https://taxbotgpt.ai/').subscribe(
    //     response => {
    //       console.log('User saved successfully!', response);
    //       this.userForm.reset(); // Reset the form after successful submission
    //       alert("You Have signup successfully. Please login now")
    //       this.route.navigateByUrl('/sign-in')
    //     },
    //     error => {
    //       console.error('Error saving user:', error);
    //       alert("Email id exist or server error");
    //     }
    //   );
    // } else {
    //   // alert("Please enter valid email or required data")
    // }
  }


  openModal() {
    this.myModal!.nativeElement.style.display = "block";

  }
  closeModal() {
    this.myModal!.nativeElement.style.display = 'none';
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
}

