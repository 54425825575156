<app-header></app-header>
<section class="main-banner p-0">
    <div class="swiper mySwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide position-relative">
                <img src="assets/images/internal-banner.jpg" alt="" class="img-fluid">
                <div class="internal-headercontent">
                    <h1> Contact <span> Us </span> </h1>
                    <!-- <p> TaxBOTGPT:Your Personal Tax Assistant </p> -->
                </div>
            </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
    </div>
</section>


<div class="main">
    <section class="internal-content graybg">
        <div class="row">
            <div class="col-md-8 shadow-lg rounded m-auto faq-row p-5">
                <h2 class="text-center mb-3"> PLEASE MESSAGE ME, <br> IF YOU HAVE ANY QUERIES </h2>
                <div class="row">
                    <div class="col-md-6">
                        <form [formGroup]="contactForm" (ngSubmit)="doSubmit()" class="mt-5">
                            <div class="input-single">
                                <input type="text" class="form-control shadow-sm" formControlName="name" placeholder="Your Name"
                                    required>
        
                                <div *ngIf="(contactForm?.get('name')?.invalid && contactForm?.get('name')?.touched ) || (contactForm?.get('name')?.invalid && isSubmitted)"
                                    class="text-danger mt-3">
                                    Please enter name
                                </div>
                            </div>
                            <div class="input-single">
                                <input type="email" class="form-control shadow-sm" formControlName="email"
                                    placeholder="Your Email" required>
        
                                <div *ngIf="(contactForm?.get('email')?.invalid && contactForm?.get('email')?.touched ) || (contactForm?.get('email')?.invalid && isSubmitted)"
                                    class="text-danger mt-3">
                                    Please enter email
                                </div>
                            </div>
                            <div class="input-single">
                                <input type="text" class="form-control shadow-sm" formControlName="phone"
                                    placeholder="Phone Number" required>
                                <div *ngIf="(contactForm?.get('phone')?.invalid && contactForm?.get('phone')?.touched ) || (contactForm?.get('phone')?.invalid && isSubmitted)"
                                    class="text-danger mt-3">
                                    Please enter name
                                </div>
                            </div>
                            <div class="input-single">
                                <textarea formControlName="description" class="form-control shadow-sm" cols="30" rows="10"
                                    placeholder="Type A Message"></textarea>
                            </div>
                            <div class="section__content-cta text-center">
                                <button type="submit" class="btn btn-submit">Submit Now</button>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-6 justify-content-center">
                        <div class="info-wrap w-100 p-lg-5 p-4">
                            <h3 class="mb-4 mt-md-4">Contact us</h3>
                            <div class="dbox w-100 d-flex align-items-start">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-map-marker"></span>
                                </div>
                                <div class="text pl-3" style="text-align: left;">
                                    <p><span>Address: </span> Under DFS Building, Annexe Building, Jeevan Vihar, Sansad Marg, New Delhi, Delhi 110001
                                    </p>
                                </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-phone"></span>
                                </div>
                                <div class="text">
                                    <p><span>Phone: </span> <a class="ps-1"> +91 9560079055</a></p>
                                </div>
                            </div>
                            <div class="dbox w-100 d-flex align-items-center">
                                <div class="icon d-flex align-items-center justify-content-center">
                                    <span class="fa fa-paper-plane"></span>
                                </div>
                                <div class="text">
                                    <p><span>Email: </span> <a class="ps-1"
                                            href="mailto:info@taxbotgpt.ai"> info&#64;taxbotgpt.ai</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>


<app-footer></app-footer>