<app-header></app-header>
<app-loader *ngIf="loading"></app-loader>
<a href="https://wa.me/919289233232" class="whatsapp-sticky" target="_blank"> 
  <img src="assets/images/whatsapp-icon.png" alt=""> </a>

  <section class="main-banner p-0">
    <owl-carousel-o [options]="bannerOptions">
      <ng-template carouselSlide>
        <img routerLink="sign-in" class="img-responsive cursor" src="assets/images/banner/Slide_1.png" alt="">
      </ng-template>  
      <ng-template carouselSlide>
        <a href="/#plans">
          <img class="img-responsive cursor" src="assets/images/banner/Slide_2.png" alt="">
        </a>
      </ng-template> 
      <ng-template carouselSlide>
        <img routerLink="sign-in" class="img-responsive cursor" src="assets/images/banner/Slide_3.png" alt="">
      </ng-template> 
      <ng-template carouselSlide>
        <img href="/#plans" class="img-responsive cursor" src="assets/images/banner/Slide_4.png" alt="">
      </ng-template>
    </owl-carousel-o>
    <!-- <div class="swiper mySwiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <a href="sign-in"> <img src="assets/images/banner.jpg" alt="" class="img-fluid d-none d-lg-block d-md-block"> </a>
          <a href="sign-in"> <img src="assets/images/mobile-banner.jpg" alt="" class="img-fluid d-lg-none d-md-none"> </a>
        </div>
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div> -->
  </section>

  <div class="main">
    <section class="services-section p-0">
        <div class="container">
          <div class="col-lg-10 offset-lg-1">
          <div class="row">
            <div class="col-lg-4 col-sm-4 col-4">
              <div class="services-details">
                <div class="services-text">
                  <p> <span> 5,000 </span> <br> Active User </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-4 col-4">
              <div class="services-details position-relative border-mid">
                <div class="services-text">
                  <p> <span> 100% </span> <br> Satisfaction </p>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-4 col-4">
              <div class="services-details position-relative border-mid">
                <div class="services-text">
                  <p> <span> 1500 </span> <br> Daily User </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>


      <section class="online-skills pt-0 pb-0">
        <div class="container">
         <h2 class="mb-5 title-gradient title-icon"> Key Features  </h2>
         <div class="row mt-5">

     <div class="col-lg-6">
        <img src="assets/images/ai-withlaptop.png" alt="" class="img-fluid"> </div>

     <div class="col-lg-6">
       <div class="online-class shadow radius p-4 overflow-hidden mb-5 text-start bg">
          <div class="bg-bluecolor p-4"> 
         <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
           <li class="nav-item" role="presentation">
             <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
               TaxBotGPT 1.0 </button>
           </li>
           <li class="nav-item" role="presentation">
             <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
               TaxBotGPT 2.0 </button>
           </li>
           <li class="nav-item" role="presentation">
             <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
               TaxBotGPT 3.0 </button>
           </li>
         </ul>
         <div class="tab-content" id="pills-tabContent">
           <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
             <div class="keyfeatures-list p-2 text-start">
               <ul>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Instant answers to your tax questions </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Access to the latest tax regulations </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Personalized advice based on your financial situation </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  User-friendly interface for easy navigation </li>
               </ul>
             </div>

           </div>
           <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">

             <div class="keyfeatures-list p-2 text-start">
               <ul>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Advance LLM Model </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  	Answer with Case law </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Hallucination Model </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Detailed answer of GST and Income Tax Act </li>
               </ul>
             </div>

           </div>
           <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">

             <div class="keyfeatures-list p-2 text-start">
               <ul>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Everything in Model 1.0 and 2.0 </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i> White labeling	 </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Customized Chatbot </li>
                 <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Customized API </li>
               </ul>
             </div>

           </div>
         </div>
         <a class="common-btn mt-4 d-inline-block" href="sign-in"> Get Started </a>
          </div>
       </div>
     </div>

         </div>
        </div>
     </section>


     <section class="online-skills pt-lg-5 mt-lg-5 pb-0 mb-0">
        <div class="container">
         <h2 class="title-gradient mb-5 title-icon"> Why Choose TaxBotGPT ? </h2>
            <div class="row my-5 mt-4">
            <!-- <div class="col-lg-6 order-lg-1">
            <img src="assets/images/key-features2.png" alt="" class="img-fluid">
            </div> -->
            <div class="col-lg-12 m-auto">
            <div class="online-class shadow radius p-4 overflow-hidden mb-5 text-start bg">
              <div class="bg-bluecolor p-4"> 
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pills-taxbot-tab" data-bs-toggle="pill" data-bs-target="#pills-taxbot" type="button" role="tab" aria-controls="pills-taxbot" aria-selected="true">
                    TaxBotGPT 1.0 </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-taxbot2-tab" data-bs-toggle="pill" data-bs-target="#pills-taxbot2" type="button" role="tab" aria-controls="pills-taxbot2" aria-selected="false">
                    TaxBotGPT 2.0 </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="pills-taxbot3-tab" data-bs-toggle="pill" data-bs-target="#pills-taxbot3" type="button" role="tab" aria-controls="pills-taxbot3" aria-selected="false">
                    TaxBotGPT 3.0 </button>
                </li>
              </ul>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="pills-taxbot" role="tabpanel" aria-labelledby="pills-taxbot-tab" tabindex="0">
                  <div class="keyfeatures-list p-2 text-start">
                    <ul>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Experience efficiency and accuracy with TaxBotGPT, </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Offering instant, LLM powered responses to your tax queries. </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Saving you time and ensuring precision in your tax-related tasks. </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i> Providing exclusive access to crucial information not easily available. </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                        Allowing you to stay ahead of the changes and make well-informed decisions. </li>
                        <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                          Consider TaxBotGPT as your trusted companion in navigating the complexities of tax regulations. </li>
                          <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                            We are committed to providing reliable and up-to-date information to you </li>
                    </ul>
                  </div>

                </div>

                <div class="tab-pane fade" id="pills-taxbot2" role="tabpanel" aria-labelledby="pills-taxbot2-tab" tabindex="0">

                  <div class="keyfeatures-list p-2 text-start">
                    <ul>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                        Instant answers to your tax questions </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                        Access to the latest tax regulations </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Personalized advice based on your financial situation </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  User-friendly interface for easy navigation </li>
                    </ul>
                  </div>

                </div>

                <div class="tab-pane fade" id="pills-taxbot3" role="tabpanel" aria-labelledby="pills-taxbot3-tab" tabindex="0">

                  <div class="keyfeatures-list p-2 text-start">
                    <ul>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                        Instant answers to your tax questions </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>
                        Access to the latest tax regulations </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  Personalized advice based on your financial situation </li>
                      <li> <i class="fa fa-check-circle" aria-hidden="true"></i>  User-friendly interface for easy navigation </li>
                    </ul>
                  </div>

                </div>
              </div>
              <a class="common-btn mt-4 d-inline-block" href="sign-in"> Get Started </a>
            </div>
            </div>
            </div>
            </div>
        </div>
      </section>

      <section id="pricing" class="pricing-content section-padding">
        <div class="container-fluid">					
          <div class="section-title text-center">
            <h2 class="title-gradient mb-4 title-icon plan-title">Choose Your Best Plan</h2>
            <p>
              AI Tax generator tools have emerged as powerful resources for unleashing creative possibilities and transforming.
            </p>  
          </div>				
          <div class="container">									
            <div class="row">
              <div 
              *ngFor="let plan of planResponse"
              class="col-lg-3 col-sm-6 col-xs-12 wow fadeInUp" 
              data-wow-duration="1s" 
              data-wow-delay="0.1s" 
              data-wow-offset="0" 
              style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;padding: 0px;">
              <div class="pricing_design">
                <div class="single-pricing">
                  <div class="price-head">		
                    <h2 style="color: #6c757d;">{{ plan.plan_name }}</h2>
                    <h4 
                      style="font-weight: 800;"
                      class="text-center" 
                      *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                      ₹{{plan.price_per_month}} /month
                    </h4>
                  </div>
                  <div class="text-center" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                    <!-- (click)="subscribPlan(plan)"  -->
                    <button 
                      [disabled]="user_subscription_plan_id == plan.id"
                      (click)="username && useremail ? subscribPlan(plan) : doLogin()"
                      class="price_btn">
                      {{ username && useremail ? 'Upgrade to '+plan.plan_name : 'Get Started' }} 
                    </button>
                  </div>
                  <div class="text-center mt-5" *ngIf="plan.plan_type=='enterprise'">
                    <a href="/enterprise-enquiry"> <button class="price_btn"> Contact for Sales </button></a>
                 </div>
                  <ul>
                    <li  *ngFor="let feature of plan.features">
                      <i class="fa fa-check-circle f-18 mr-2"></i> {{feature}}
                    </li>
                  </ul>
                  <div class="text-center" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                    <!-- (click)="subscribPlan(plan)"  -->
                    <button 
                      [disabled]="user_subscription_plan_id == plan.id"
                      (click)="username && useremail ? subscribPlan(plan) : doLogin()"
                      class="price_btn">
                      {{ username && useremail ? 'Upgrade to '+plan.plan_name : 'Get Started' }} 
                    </button>
                  </div>
                  <div class="text-center mt-5" *ngIf="plan.plan_type=='enterprise'">
                    <a href="/enterprise-enquiry"> <button class="price_btn"> Contact for Sales </button></a>
                 </div>
                </div>
              </div>
            </div>	
            </div>  
          </div>
        </div>
      </section>

      <!-- <section class="price-plan p-0 m-0" id="plans">
        <div class="container">
          <h2 class="title-gradient"> CHOOSE YOUR BEST PLAN </h2>
          <p class="mb-5 text-center"> AI Tax generator tools have emerged as powerful resources for unleashing creative possibilities and transforming. </p>


          <ul class="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-monthly" type="button" role="tab" aria-controls="pills-monthly" aria-selected="true" (click)="getplan('monthly')"> Monthly </button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-yearly-tab" data-bs-toggle="pill" data-bs-target="#pills-yearly" type="button" role="tab" aria-controls="pills-yearly" aria-selected="false" (click)="getplan('yearly')"> Yearly </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">

              <div class="mt-5 table-responsive-lg aos-init aos-animate rounded" data-aos="fade-up">
                <table class="table table-lg table-borderless bg-lightblue">
                    <thead class="border-bottom">
                        <tr class="text-center">
                          
                            <th scope="col" class="text-left bg-blues">
                                <div class="mb-5">
                                    <div class="h4 font-weight-bold" style="color: #17A96C !important;"> Features </div>
                                    
                                </div>
                            </th>
                            <th scope="col" class="text-center text-nowrap bg-blues max-170">
                              <div class="h4 font-weight-bold mb-0" style="color: #F9C618 !important;"> Free </div>
                              <p class="font-weight-normal text-muted p-0 m-0">  </p>
                              <div class="mt-4 pt-2"> &nbsp; </div>
                               
                          </th>
                            <ng-container *ngFor="let plan of planResponse">
                             
                              <th scope="col" class="text-center text-nowrap bg-blues" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                                  <div class="h4 font-weight-bold mb-0" style="color: #33BCE3 !important;">{{ plan.plan_name}}</div>
                                  <p class="font-weight-normal text-muted p-0 m-0" style="color: #33BCE3 !important;"> ₹{{ plan.amount}}/{{ plan.billing_cycle}} </p>
                                  <button 
                                    [disabled]="user_subscription_plan_id == plan.id"
                                    (click)="username && useremail ? subscribPlan(plan) : doLogin()"
                                    class="btn btn-upgrade btn-sm waves-effect mt-3" 
                                    role="button"> {{ username && useremail ? 'Upgrade' : 'Get Started' }}  
                                </button>
                              </th>
                            </ng-container>
                            <th scope="col" class="text-center text-nowrap bg-blues">
                              <div class="h4 font-weight-bold mb-0">Enterprise</div>
                              <p class="font-weight-normal text-muted p-0 m-0"> &nbsp; </p>
                              <a routerLink="enterprise-enquiry" class="btn btn-contact btn-sm waves-effect mt-3" role="button"> Contact </a>
                          </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-bottom bg-light">
                            <th scope="row" class="h6 py-3 font-weight-semibold text-nowrap border-0 mb-0" style="border-bottom:#092a4b solid 2px !important;"> TaxBotGPT Model 1.0 </th>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0 " style="border-bottom:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important;"></td>
                        </tr>
                        <tr>
                          <th class="font-weight-normal" scope="row"> Basic LLM Model  </th>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center "><span class="say-yes"></span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Only  5 Question Allow </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>
                        <tr>
                          <th class="font-weight-normal" scope="row"> 100 Questions allow with 1.0 </th>
                          <td class="text-center"><span>-</span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center "><span>-</span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>
                      <tr>
                        <th class="font-weight-normal" scope="row"> 500 Questions allow with 1.0 </th>
                        <td class="text-center"><span>-</span></td>
                        <td class="text-center"><span>-</span></td>
                        <td class="text-center "><span class="say-yes"></span></td>
                        <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Answer of GST and Income Tax Act </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Ask Moderate level professional query </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Legal reply can be prepared </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> 2400-words limitation in Answer  </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                          <th class="font-weight-normal" scope="row"> Hallucination Model </th>
                          <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                        <tr class="border-top border-bottom bg-light">
                            <th scope="row" class="h6 py-3 font-weight-semibold border-0 mb-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"> TaxBotGPT Model 2.0 </th>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0 " style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Advance LLM Model
                            </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Only  20 Question Allow </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>
                        <tr>
                          <th class="font-weight-normal" scope="row"> 20 Questions Allow with 2.0 </th>
                          <td class="text-center">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center "><span>-</span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>
                      <tr>
                        <th class="font-weight-normal" scope="row"> 50 Questions Allow with 2.0 </th>
                        <td class="text-center">-</td>
                        <td class="text-center"><span>-</span></td>
                        <td class="text-center "><span class="say-yes"></span></td>
                        <td class="text-center"><span class="say-yes"></span></td>
                    </tr>
                    <tr>
                      <th class="font-weight-normal" scope="row"> Advanced tools for professionals and larger organizations</th>
                      <td class="text-center">-</td>
                      <td class="text-center"><span>-</span></td>
                      <td class="text-center "><span class="say-yes"></span></td>
                      <td class="text-center"><span class="say-yes"></span></td>
                  </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Hallucination Model
                            </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Detailed answer of GST and Income Tax Act </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center ">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                          <th class="font-weight-normal" scope="row"> Ask Advance Level professional query </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                        <th class="font-weight-normal" scope="row"> Answer with Case law </th>
                        <td class="text-center">-</td>
                        <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                    </tr>

                    <tr>
                      <th class="font-weight-normal" scope="row"> 72 type of legal reply format updated </th>
                      <td class="text-center">-</td>
                      <td class="text-center"><span class="say-yes"></span></td>
                      <td class="text-center "><span class="say-yes"></span></td>
                      <td class="text-center"><span class="say-yes"></span></td>
                  </tr>

                  <tr>
                    <th class="font-weight-normal" scope="row"> 6000 words answer  </th>
                    <td class="text-center">-</td>
                    <td class="text-center"><span class="say-yes"></span></td>
                    <td class="text-center "><span class="say-yes"></span></td>
                    <td class="text-center"><span class="say-yes"></span></td>
                </tr>


                        <tr class="border-top border-bottom bg-light">
                            <th scope="row" class="h6 py-3 font-weight-semibold border-0 mb-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"> TaxBotGPT Model 3.0 </th>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                            <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                        </tr>

                        <tr>
                          <th class="font-weight-normal" scope="row"> Everything in Model 1.0 and 2.0
                          </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                          <th class="font-weight-normal" scope="row"> White labeling  </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                          <th class="font-weight-normal" scope="row"> Customized Chatbot
                          </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                          <th class="font-weight-normal" scope="row"> Auto Reply to Dept </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                        <th class="font-weight-normal" scope="row"> Multiple login </th>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center ">-</td>
                        <td class="text-center"><span class="say-yes"></span></td>
                    </tr>

                    <tr>
                      <th class="font-weight-normal" scope="row"> No limit for questions </th>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center ">-</td>
                      <td class="text-center"><span class="say-yes"></span></td>
                  </tr>
                  <tr class="text-center">
                          
                    <th scope="col" class="text-left bg-blues">
                        <div class="mb-5">
                            <div class="h4 font-weight-bold" style="color: #17A96C !important;">  </div>
                            
                        </div>
                    </th>
                    <th scope="col" class="text-center text-nowrap bg-blues max-170">
                      <div class="h4 font-weight-bold mb-0" style="color: #F9C618 !important;"> Free </div>
                      <p class="font-weight-normal text-muted p-0 m-0">  </p>
                      <div class="mt-4 pt-2"> &nbsp; </div>
                      
                  </th>
                    <ng-container *ngFor="let plan of planResponse">
                     
                      <th scope="col" class="text-center text-nowrap bg-blues" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                          <div class="h4 font-weight-bold mb-0" style="color: #33BCE3 !important;">{{ plan.plan_name}}</div>
                          <p class="font-weight-normal text-muted p-0 m-0" style="color: #33BCE3 !important;"> ₹{{ plan.amount}}/{{ plan.billing_cycle}} </p>
                          <button 
                            [disabled]="user_subscription_plan_id == plan.id"
                            (click)="username && useremail ? subscribPlan(plan) : doLogin()"
                            class="btn btn-upgrade btn-sm waves-effect mt-3" 
                            role="button"> {{ username && useremail ? 'Upgrade' : 'Get Started' }}  
                        </button>
                      </th>
                    </ng-container>
                    <th scope="col" class="text-center text-nowrap bg-blues">
                      <div class="h4 font-weight-bold mb-0">Enterprise</div>
                      <p class="font-weight-normal text-muted p-0 m-0"> &nbsp; </p>
                      <a routerLink="enterprise-enquiry" class="btn btn-contact btn-sm waves-effect mt-3" role="button"> Contact </a>
                  </th>
                   
                </tr>
                    </tbody>
                </table>
            </div>

            </div>
            <div class="tab-pane fade" id="pills-yearly" role="tabpanel" aria-labelledby="pills-yearly-tab">
              <div class="mt-5 table-responsive-lg aos-init aos-animate rounded" data-aos="fade-up">
                <table class="table table-lg table-borderless bg-lightblue">
                    <thead class="border-bottom">
                        <tr class="text-center">
                            <th scope="col" class="text-left bg-blues">
                                <div class="mb-5">
                                    <div class="h4 font-weight-bold" style="color: #17A96C !important;"> Features </div>
                                    
                                </div>
                            </th>

                            <th scope="col" class="text-center text-nowrap bg-blues max-170">
                              <div class="h4 font-weight-bold mb-0" style="color: #F9C618 !important;"> Free </div>
                              <p class="font-weight-normal text-muted p-0 m-0">  </p>
                              <div class="mt-4 pt-2"> &nbsp; </div>
                              
                          </th>
                            <ng-container *ngFor="let plan of planResponse">
                             
                              <th scope="col" class="text-center text-nowrap bg-blues max-170" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                                  <div class="h4 font-weight-bold mb-0" style="color: #33BCE3 !important;">{{ plan.plan_name}}</div>
                                  <p class="font-weight-normal text-muted p-0 m-0" style="color: #33BCE3 !important;"> ₹{{ plan.amount}}/{{ plan.billing_cycle}} </p>
                                  <button 
                                    [disabled]="user_subscription_plan_id == plan.id"
                                    class="btn btn-upgrade btn-sm waves-effect mt-3" 
                                    role="button"
                                    (click)="username && useremail ? subscribPlan(plan) : doLogin()"> 
                                    {{ username && useremail ? 'Upgrade' : 'Get Started' }} 
                                  </button>
                              </th>
                            </ng-container>
                            
                            <th scope="col" class="text-center text-nowrap bg-blues max-170">
                                <div class="h4 font-weight-bold mb-0">Enterprise</div>
                                <p class="font-weight-normal text-muted p-0 m-0"> &nbsp; </p>
                                <a routerLink="enterprise-enquiry" class="btn btn-contact btn-sm waves-effect mt-3" role="button"> Contact </a>
                            </th> 
                        </tr>
                    </thead>
                    <tbody>
                      <tr class="border-bottom bg-light">
                        <th scope="row" class="h6 py-3 font-weight-semibold text-nowrap border-0 mb-0" style="border-bottom:#092a4b solid 2px !important;"> TaxBotGPT Model 1.0 </th>
                        <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important;"></td>
                        <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important;"></td>
                        <td class="py-3 border-0 " style="border-bottom:#092a4b solid 2px !important;"></td>
                        <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important;"></td>
                    </tr>
                        <tr>
                          <th class="font-weight-normal" scope="row"> Basic LLM Model  </th>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center "><span class="say-yes"></span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Only  5 Question Allow </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>
                        <tr>
                          <th class="font-weight-normal" scope="row"> 100 Questions allow with 1.0 </th>
                          <td class="text-center"><span>-</span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center "><span>-</span></td>
                          <td class="text-center"><span>-</span></td>
                      </tr>
                      <tr>
                        <th class="font-weight-normal" scope="row"> 500 Questions allow with 1.0 </th>
                        <td class="text-center"><span>-</span></td>
                        <td class="text-center"><span>-</span></td>
                        <td class="text-center "><span class="say-yes"></span></td>
                        <td class="text-center"><span class="say-yes"></span></td>
                      </tr>


                        <tr>
                            <th class="font-weight-normal" scope="row"> Answer of GST and Income Tax Act </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Ask Moderate level professional query </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Legal reply can be prepared </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> 2400-words limitation in Answer  </th>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                          <th class="font-weight-normal" scope="row"> Hallucination Model </th>
                          <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr class="border-top border-bottom bg-light">
                        <th scope="row" class="h6 py-3 font-weight-semibold border-0 mb-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"> TaxBotGPT Model 2.0 </th>
                        <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                        <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                        <td class="py-3 border-0 " style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                        <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                    </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Advance LLM Model
                            </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Only  20 Question Allow </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>
                        <tr>
                          <th class="font-weight-normal" scope="row"> 20 Questions Allow with 2.0 </th>
                          <td class="text-center">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                          <td class="text-center "><span class="say-yes"></span></td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>
                      <tr>
                        <th class="font-weight-normal" scope="row"> 50 Questions Allow with 2.0 </th>
                        <td class="text-center">-</td>
                        <td class="text-center"><span>-</span></td>
                        <td class="text-center "><span class="say-yes"></span></td>
                        <td class="text-center"><span class="say-yes"></span></td>
                    </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Hallucination Model
                            </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                            <th class="font-weight-normal" scope="row"> Detailed answer of GST and Income Tax Act </th>
                            <td class="text-center">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center ">-</td>
                            <td class="text-center"><span class="say-yes"></span></td>
                        </tr>

                        <tr>
                          <th class="font-weight-normal" scope="row"> Ask Advance Level professional query </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                        <th class="font-weight-normal" scope="row"> Answer with Case law </th>
                        <td class="text-center">-</td>
                        <td class="text-center"><span class="say-yes"></span></td>
                            <td class="text-center "><span class="say-yes"></span></td>
                            <td class="text-center"><span class="say-yes"></span></td>
                    </tr>

                    <tr>
                      <th class="font-weight-normal" scope="row"> 72 type of legal reply format updated </th>
                      <td class="text-center">-</td>
                      <td class="text-center"><span class="say-yes"></span></td>
                      <td class="text-center "><span class="say-yes"></span></td>
                      <td class="text-center"><span class="say-yes"></span></td>
                  </tr>

                  <tr>
                    <th class="font-weight-normal" scope="row"> 6000 words answer  </th>
                    <td class="text-center">-</td>
                    <td class="text-center"><span class="say-yes"></span></td>
                    <td class="text-center "><span class="say-yes"></span></td>
                    <td class="text-center"><span class="say-yes"></span></td>
                </tr>

                        <tr class="border-top border-bottom bg-light">
                          <th scope="row" class="h6 py-3 font-weight-semibold border-0 mb-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"> TaxBotGPT Model 3.0 </th>
                          <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                          <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                          <td class="py-3 border-0 " style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                          <td class="py-3 border-0" style="border-bottom:#092a4b solid 2px !important; border-top:#092a4b solid 2px !important;"></td>
                      </tr>

                        <tr>
                          <th class="font-weight-normal" scope="row"> Everything in Model 1.0 and 2.0
                          </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                          <th class="font-weight-normal" scope="row"> White labeling  </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                          <th class="font-weight-normal" scope="row"> Customized Chatbot
                          </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                          <th class="font-weight-normal" scope="row"> Auto Reply to Dept </th>
                          <td class="text-center">-</td>
                          <td class="text-center">-</td>
                          <td class="text-center ">-</td>
                          <td class="text-center"><span class="say-yes"></span></td>
                      </tr>

                      <tr>
                        <th class="font-weight-normal" scope="row"> Multiple login </th>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center ">-</td>
                        <td class="text-center"><span class="say-yes"></span></td>
                    </tr>

                    <tr>
                      <th class="font-weight-normal" scope="row"> No limit for questions </th>
                      <td class="text-center">-</td>
                      <td class="text-center">-</td>
                      <td class="text-center ">-</td>
                      <td class="text-center"><span class="say-yes"></span></td>
                  </tr>
                    </tbody>
                </table>
            </div>

            </div>
          </div>



        </div>
      </section> -->

    <section Class="solution-section">
    <div Class="container">
    <div Class="col-lg-12">
    <div Class="bg p-4 shadow">
    <div class="row">

    <div Class="col-lg-4">
    <div class="solutions-thumbnail position-relative bg-bluecolor p-4">
    <img src="assets/images/smart-learning-icon.png" Class="img-fluid">
    <div Class="solution-content">
    <h4 class="pt-3"> Smart Learning  </h4>
    <p class="taxgpt-content pt-3"> TaxbotGPT goes beyond static responses, learning and adapting from each user interaction to deliver intelligent insights. </p>
    </div>
    </div>
    </div>

    <div Class="col-lg-4">
    <div class="solutions-thumbnail position-relative bg-bluecolor p-4">
    <img src="assets/images/intgreating-cloud-icon.png" Class="img-fluid">
    <div Class="solution-content pt-2">
    <h4 class="pt-3"> Advance AI GPT </h4>
    <p class="taxgpt-content pt-3"> AI chatbots can learn from user interactions and improve over time.
      </p>
    </div>
    </div>
    </div>

    <div Class="col-lg-4">
    <div class="solutions-thumbnail position-relative bg-bluecolor p-4">
    <img src="assets/images/advance-gpt-icon.png" Class="img-fluid zoom-effect">
    <div Class="solution-content pt-2">
    <h4 class="pt-3"> Integrating cloud AI </h4>
    <p class="taxgpt-content pt-3"> Integration into the cloud for a smarter and more adaptive experience, by learning from user interactions, provides you with intelligent responses. </p>
    </div>
    </div>
    </div>
    
    </div>
     </div>
    </div>
    </div>
    </section>

    <section class="how-towork text-center">
    <div class="container-fluid">
      <div class="row">
        <h2 class="title-gradient title-icon position-relative d-inline-block"> How TaxBotGPT Work </h2>
        <!-- <p class="mb-5"> CASE studies that show how our expertise turns challenges into victories, </p> -->

        <img src="../../assets/images/TBGPT-mid-bannerr.png" alt="" class="img-fluid mt-5">
        <!-- <img src="../../assets/images/taxbot-gptwork.png" alt="" class="img-fluid mt-5"> -->

        <!-- <div class="howtobox mt-2 d-lg-flex justify-content-center align-item-center text-center">

          <div class="howtobox-col">
            <h3> Input <br> Processing </h3>
            <div class="howicon">
              <img src="assets/images/input-processing-icon.png" alt="" class="img-fluid">
            </div>
            <p class="howto-content"> Upon receiving a query, TaxBOTGPT analyzes the text to understand the question's context and specifics. </p>
          </div>

          <div class="howtobox-col">
            <h3> Knowledge  <br> Retrieval </h3>
            <div class="howicon">
              <img src="assets/images/input-processing-icon.png" alt="" class="img-fluid">
            </div>
            <p class="howto-content"> It searches its extensive database, containing tax legislation, official guidelines, and previously addressed inquiries, to find relevant information. </p>
          </div>

          <div class="howtobox-col">
            <h3> Response  <br> Generation </h3>
            <div class="howicon">
              <img src="assets/images/input-processing-icon.png" alt="" class="img-fluid">
            </div>
            <p class="howto-content"> TaxBOTGPT crafts an answer in natural language to ensure it is easily comprehensible. </p>
          </div>

          <div class="howtobox-col">
            <h3> Feedback  <br> Loop </h3>
            <div class="howicon">
              <img src="assets/images/input-processing-icon.png" alt="" class="img-fluid">
            </div>
            <p class="howto-content"> Users can offer feedback on the usefulness and accuracy of responses, aiding the system in learning and improving its performance. </p>
          </div>

          <div class="howtobox-col">
            <h3> BOOKSTORE  <br> AI </h3>
            <div class="howicon">
              <img src="assets/images/input-processing-icon.png" alt="" class="img-fluid">
            </div>
            <p class="howto-content"> Get ready for a whole new reading experience with Bookstore AI. It suggests awesome books and keeps track of what’s in stock. </p>
          </div>

        </div> -->

      </div>
    </div>
    </section>

    <section class="faq-section youtube-section" style="padding-bottom: 0px;">
      <div class="container">
        <div class="col-lg-12 m-auto faq-row youtube-section">
          <h3 class="mb-5 text-center title-gradient title-icon position-relative d-inline-block text-center"> 
            How to use TaxBotGPT
          </h3>
          <div class="container overflow-hidden mt-4 pb-5">
            <div class="row gy-4">
              <owl-carousel-o [options]="youtubeOptions">
                <ng-template carouselSlide *ngFor="let video of youtubeVideos">
                  <div class="text-secondary bg-light2 img-container">
                    <iframe height="170" width="250" [src]="video | safe"></iframe>
                    <div class="overlay" (click)="showVideo(video)">
                      <span class="fa fa-play"></span>
                    </div>
                  </div>
                </ng-template>  
                <!-- <ng-template carouselSlide>
                  <div class="text-secondary bg-light2">
                    <iframe height="170" width="250" src="https://www.youtube.com/embed/2hJFqsp2spw"></iframe>
                  </div>
                </ng-template>  
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light2">
                    <iframe height="170" width="250" src="https://www.youtube.com/embed/QmIOgPYsc1M"></iframe>
                  </div>
                </ng-template> 
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light2">
                    <iframe height="170" width="250" src="https://www.youtube.com/embed/LO3425-gEuE"></iframe>
                  </div>
                </ng-template>  -->
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq-section">
      <div class="container">
        <div class="col-lg-12 m-auto faq-row p-5">
          <h3 class="mb-5 text-center title-gradient title-icon position-relative d-inline-block text-center"> 
            Featured by
          </h3>
          <div class="container overflow-hidden mt-4">
            <div class="row gy-4">
              <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light">
                    <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                      <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo1.png" />
                    </svg>
                  </div>
                </ng-template>  
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light">
                    <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                      <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo2.png" />
                    </svg>
                  </div>
                </ng-template>  
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light">
                    <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                      <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo3.png" />
                    </svg>
                  </div>
                </ng-template> 
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light">
                    <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                      <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo4.png" />
                    </svg>
                  </div>
                </ng-template>  
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light">
                    <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                      <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo5.png" />
                    </svg>
                  </div>
                </ng-template> 
                <ng-template carouselSlide>
                  <div class="text-secondary bg-light">
                    <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                      <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo6.png" />
                    </svg>
                  </div>
                </ng-template> 
              </owl-carousel-o>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="container">
        <div class="col-lg-12 m-auto faq-row p-5">
          <h3 class="mb-5 text-center title-gradient title-icon position-relative d-inline-block text-center"> 
            features By
          </h3>
          <div class="container overflow-hidden">
            <div class="row gy-4">
              <div class="col-6 col-md-4 col-xl-3">
                <div class="text-secondary bg-light">
                  <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                    <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo1.png" />
                  </svg>
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3">
                <div class="text-secondary bg-light">
                  <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                    <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo2.png" />
                  </svg>
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3">
                <div class="text-secondary bg-light">
                  <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                    <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo3.png" />
                  </svg>
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3">
                <div class="text-secondary bg-light">
                  <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                    <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo4.png" />
                  </svg>
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3">
                <div class="text-secondary bg-light">
                  <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                    <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo5.png" />
                  </svg>
                </div>
              </div>
              <div class="col-6 col-md-4 col-xl-3">
                <div class="text-secondary bg-light">
                  <svg height="170" width="250" xmlns="http://www.w3.org/2000/svg">
                    <image x="0" y="30" width="250" height="100" href="assets/images/logos/logo6.png" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </section>

      <section class="faq-section" style="padding-top: 0px;">
        <div class="container">
          <div class="col-lg-12 m-auto faq-row p-5">
            <h3 class="mb-5 text-center title-gradient title-icon position-relative d-inline-block text-center"> 
              Frequently Asked Questions (FAQs)
            </h3>

      <div class="faq-bg p-4 shadow"> 

            <div class="accordion accordion-flush" id="accordionFlushExample">

              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    How does TaxBotGPT address my Income Tax & GST related inquiries?
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> TaxbotGPT utilises artificial intelligence language models and our exclusive dataset containing the latest tax information to deliver precise responses.  </div>
                </div>
              </div>

              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Is there a risk of TaxBotGPT generating inaccurate or misleading information?
                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> TaxBotGPT is in the process of compiling a database comprising tax-related documents, and it adheres strictly to the information within that scope. Even though TaxBotGPT is currently in beta, it's important to note that it's only intended for educational, research, and reference purposes. The responses provided by TaxBotGPT should not be considered tax advice. </div>
                </div>
              </div>


              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Is TaxBotGPT a safe platform by security measures?
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> Safeguarding your information is our top priority. TaxBotGPT ensures security measures to protect your data. However, it's important to note that you should not share sensitive information. </div>
                </div>
              </div>

              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    Is TaxbotGPT capable of addressing Income Tax and GST law queries from all countries?
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> TaxBotGPT is a website based in India. Currently, we are providing solutions to the queries related to Indian Income Tax and GST Laws only. </div>
                </div>
              </div>


              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    What sets TaxBotGPT apart from ChatGPT?
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> We continuously enhance TaxBotGPT in our public beta phase. It's crucial to recognize that TaxBotGPT is an educational, research, and reference tool, and none of its responses should be considered legal tax advice.

                  </div>
                </div>
              </div>


              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    What if the provided information is incorrect ?
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> As we have established already, TaxBotGPT is only for educational and research purposes, Hence users must check with other credible sources before making any necessary decision and not treat the answers provided as factual Tax Advice.
                  </div>
                </div>
              </div>


              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingSeven">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                    Is TaxBotGPT helpful to File Income Tax or GST Returns ?
                  </button>
                </h2>
                <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> Because TaxBotGPT is in beta, it will continuously strive to improve its knowledge with the assistance of the LLM technology as it is only for educational and research purposes. And the feedback we receive from the users is always taken into consideration when improving our solutions.
                  </div>
                </div>
              </div>


              <div class="accordion-item faqbg-bluecolor">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                    Do users have to pay to ask questions ?
                  </button>
                </h2>
                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body"> Asking questions on TaxBotGPT is free! You can inquire about tax-related queries without any charge. However, we do offer subscription plans for additional services and features beyond basic inquiries. Check out our subscription plans for more details on the premium offerings.


                  </div>
                </div>
              </div>

            </div>
         
          </div>

          </div>
        </div>
      </section>

    </div>
    <app-footer></app-footer>

    <button 
    style="display: none;"
    #youtube
    type="button" 
    class="btn btn-primary" 
    data-bs-toggle="modal" 
    data-bs-target="#youtube">
    Open modal
</button>

    <div class="modal" id="youtube" data-bs-backdrop='static'>
      <div class="modal-dialog vertical-align-center modal-dialog-scrollable">
        <div class="modal-content bg-dark modal-md">
          <div class="modal-header">
              <button type="button" #closeNoticeType class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <iframe id="Geeks3" width="450" height="350"
              [src]="youtubeVideo | safe" 
              frameborder="0"
              allowfullscreen>
          </iframe>
          </div>        
        </div>
      </div>
    </div>
