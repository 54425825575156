

<footer id="footer" class="p-5">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-4 footer-links col-6">
          <a href="/">
            <img src="assets/images/logo.png" class="img-fluid">
          </a>
          <!-- <p class="mt-3"> Copyright © 2024TaxBotGPT . Powered by Tax4wealth </p> -->
        </div>
        <div class="col-lg-2 col-md-2 footer-links col-6">
          <ul>
            <li><a href="/">  Home  </a></li>
            <li><a href="about-us">  About Us  </a></li>
            <li><a href="vendor/onboarding">  Vendor </a></li>
            <li><a href="contact-us">  Contact Us </a></li>

          </ul>
        </div>
        <div class="col-lg-3 col-md-3 footer-links text-end">
          <ul>
            <li><a href="privacy-policy"> Privacy Policy </a></li>
            <li><a href="term-condition">Terms & Conditions </a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-3 footer-links text-end">
          <div class="social">
            <a href="https://www.facebook.com/profile.php?id=61556184455755" target="_blank"
                aria-label="share us on facebook" title="facebook">
                <i class="fa-brands fa-facebook-f"></i>
            </a>
            <a href="https://twitter.com/Taxbotgpt" target="_blank"
                aria-label="share us on twitter" title="twitter">
                <i class="fa-brands fa-twitter"></i>
            </a>
            <a href="https://www.linkedin.com/company/tax-bot-gpt/" target="_blank"
                aria-label="share us on pinterest" title="linkedin">
                <i class="fa-brands fa-linkedin-in"></i>
            </a>
          
        </div>
        </div>
      </div>
    </div>
  </div>
</footer>
<div class="footer">
  <p>Copyright © {{ year }}TaxBotGPT . Powered by TaxBotGPT.AI</p>
</div>
