import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { LoaderComponent } from '../common/loader/loader.component';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoaderComponent],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent {
  forgorPasswordForm!: FormGroup
  email: any
  key: any
  expiry: any
  linkExpired: boolean = false
  loading: boolean = false

  timestamp: any = Math.floor(Date.now() / 1000);

  constructor(
    private fb: FormBuilder,
    private activated_route: ActivatedRoute,
    private dataService: UserService,
    private toastr: ToastrService,
    private router: Router
    ){}

  ngOnInit(): void {
    this.email = this.activated_route.snapshot.paramMap.get("email") 
    this.key = this.activated_route.snapshot.paramMap.get("key")
    this.expiry = this.activated_route.snapshot.paramMap.get("expiry")

    if(this.timestamp > this.expiry){
      this.linkExpired = true
    }

    this.forgorPasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    },{
      validator: this.confirmedValidator('password', 'confirm_password')
    })
  }

  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
        if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ confirmedValidator: true });
        } else {
          matchingControl.setErrors(null);
      }
    }
  }

  changePassword(){
    this.loading = true
    let passObj = {
      "password": this.forgorPasswordForm.controls['password'].value,
      "email": this.email,
      "key": this.key
    }

    this.dataService.post('change-password', passObj)
    .subscribe((response: any) => {
      console.log("response : ", response)
      this.loading = false
      if(response.status == 200){
        this.toastr.success(response.message)
        this.router.navigateByUrl('/sign-in')
      }else{
        this.toastr.error("Oops..Something went wrong!")
      }
    }, (error: any) => {
      console.log("---- :", error)
      this.toastr.error("Oops..Something went wrong!")
      this.loading = false
    })
  }

  home(){
    this.router.navigateByUrl('/')
  }
}
