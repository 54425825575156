<header class="mainheader">
  <div class="container">
      <div class="header">
          <div class="row">
          <div class="col-lg-3 col-6">
              <div class="header-logo mt-2">
                <a href="/">
                  <img src="assets/images/logo.png" class="img-fluid"></a>
              </div>
          </div>
            <div class="col-lg-9 col-6">

           <div class="mainmenu">
               <div class="stellarnav">
                <ul>
                  <li><a href="/">  Home  </a></li>
                  <!-- <li><a href="about-us">  About Us  </a></li> -->
                     <li><a href="/#plans">Pricing </a></li> 
                  <li><a href="/contact-us">  Contact Us </a></li>
                  <li class="signin-btn" *ngIf="!username && !useremail"><a href="/sign-in">Try TaxBotGPT</a></li>
                  <li class="signin-btn" *ngIf="username && useremail">
                    <a (click)="dashboard()">Dashboard</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>
</header>
