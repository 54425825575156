
<div class="container mt-5">
    <div class="row">
        <div #divscroll class="col-lg-12 col-md-12 min-vh-100  content markdown">
            <div class="mb-5 markdown" *ngFor="let chat of reply">
            <div class="mb-4">
                <strong class="question"> {{ chat.question}} </strong>
            </div>
            <div class="row">
                <div class="col-md-1" style="width: 4%;">
                    <img class="avatar avatar-24 chat-avatar p-1 bg-light rounded-circle"
                        src="assets/images/taxbotgpt-30.png">
                </div>
                <div class="col-md-11">
                    <markdown
                        ngPreserveWhitespaces
                        [data]="chat.response">
                    </markdown>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
