import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { HeaderComponent } from "./common/header/header.component";
import { FooterComponent } from "./common/footer/footer.component";
import { LoaderComponent } from './common/loader/loader.component';


@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
    imports: [HttpClientModule, CommonModule, RouterOutlet, RouterModule, HeaderComponent, FooterComponent, LoaderComponent]
})
export class AppComponent {
  title = 'taxbotgpt';
}
